<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>
      <b-col sm="5" md="6">
        <b-form-group label="Filtro" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
          <b-input-group size="sm">
            <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Escribe para buscar"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Borrar</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6">
        <b-form-group label="Por página" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
          <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table striped bordered hover class="table-users" show-empty stacked="md" :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" @filtered="onFiltered">
    </b-table>
  </b-container>
</template>

<script>
import Amplify from 'aws-amplify'

export default {
  data() {
    return {
      items: [],
      fields: [
        { key: 'timestamp', label: 'Fecha', sortable: true, sortDirection: 'desc' },
        { key: 'name', label: 'Nombre', sortable: true, sortDirection: 'desc' },
        { key: 'email', label: 'Email', sortable: true, sortDirection: 'desc' },
        { key: 'payType', label: 'Pagado con', sortable: true, sortDirection: 'desc' },
        { key: 'description', label: 'Descripción' },
        { key: 'amount', label: 'Cantidad' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortBy: 'timestamp',
      sortDesc: true,
      filter: null,
      filterOn: []
    }
  },
  mounted() {
    this.$vs.loading()
    this.getTransactions()
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getTransactions() {
      this.items = []
      let userIdTran = this.$store.state.user.id || '0'
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Get stats
        this.$axios.get(`/trans/${userIdTran}`, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          this.composeUsersData(response.data)
          this.$vs.loading.close()
        }).catch(() => {
          this.$vs.loading.close()
        })
        // FIN Get stats
      }).catch(() => {
        this.$vs.loading.close()
      })
    },
    composeUsersData(dataUsers) {
      for (const user of dataUsers.items) {
        let userData = {
          amount: user['amount'],
          description: user['description'],
          payType: user['payType'],
          timestamp: this.$moment(user['timestamp']).format('DD-MM-YYYY hh:mm:ss'),
          name: 'Pepe',
          email: 'pepe@pepe.com'
        }
        if (user['amount'] < 0) {
          userData._rowVariant = 'warning'
        }
        this.items.push(userData)
      }
      this.totalRows = dataUsers.count
    }
  }
}
</script>
<style scoped>
.table-users {
  margin-top: 20px;
}
.pagination-table {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>